import { useIsomorphicLayoutEffect, useSprings } from 'react-spring'
import { useEvent } from '/machinery/useEvent'
import { useRefs } from '/machinery/useRefs'

export function useFlipSprings(count, config) {
  const refs = useRefs(count)
  const [springs, api] = useSprings(count, () => ({ x: 0, y: 0, scaleX: 1, scaleY: 1, config }))
  const [rectByRef] = React.useState(() => new WeakMap())

  // TODO: maybe move to the transition function, after calling ReactDOM.flushSync() (if that works)
  useIsomorphicLayoutEffect(() => {
    api.start(i => {
      const ref = refs[i]

      const { width, height, left, top } = ref.current.getBoundingClientRect()
      const rect = rectByRef.get(ref)

      const scaleX = rect ? (rect.width / width) : 1
      const scaleY = rect ? (rect.height / height) : 1
      const x = rect ? (rect.left - left) : 0
      const y = rect ? (rect.top - top) : 0

      return { from: { x, y, scaleX, scaleY }, x: 0, y: 0, scaleX: 1, scaleY: 1 }
    })
  })

  return {
    refs,
    springs,
    transition: useEvent(transition)
  }

  function transition(fn) {
    refs.forEach((ref, i) => {
      const rect = ref.current.getBoundingClientRect()
      rectByRef.set(ref, {
        width: rect.width,
        height: rect.height,
        left: rect.left,
        top: rect.top,
      })
    })

    fn()
  }
}

export function useFlipSpring(config) {
  const { refs: [ref], springs: [spring], transition } = useFlipSprings(1, config)
  return { ref, spring, transition }
}
