export function useRefs(count) {
  const [refs, setRefs] = React.useState(() => Array(count).fill().map(() => React.createRef()))

  React.useEffect(
    () => {
      setRefs(x => [
        ...x.slice(0, count),
        ...Array(Math.max(0, count - x.length)).fill().map(() => React.createRef())
      ])
    },
    [count]
  )

  return refs
}
