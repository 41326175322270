import { animated, useSpring, config } from 'react-spring'
import { useGesture } from '@use-gesture/react'
import { clamp } from '@kaliber/math'
import { useFlipSpring, useFlipSprings } from '/machinery/useFlipSpring'
import { useTranslate } from '/machinery/I18n'
import { ImageCover } from '/features/buildingBlocks/Image'
import { SubheadingSm } from '/features/buildingBlocks/Subheading'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

import styles from './BrandInformation.css'

import iconClose from '/images/icons/close.raw.svg'

export function BrandInformation({ brandInformation }) {
  const { title, subHeader } = brandInformation
  const slides = brandInformation.slides || []
  const { __ } = useTranslate()
  const [expanded, setExpanded] = React.useState(false)
  const { refs: slideRefs, springs: slideSprings, transition: transitionSlides } = useFlipSprings(slides.length, config.stiff)
  const { ref: dotRef, spring: dotSpring, transition: transitionDot } = useFlipSpring(config.stiff)
  const { bind, spring: sliderSpring, api } = useSlider({ enabled: expanded })

  return (
    <div className={styles.component} style={{ '--slide-count': slides.length }}>
      <ContainerXl>
        <div className={styles.layout}>
          <div className={cx(styles.text, expanded && styles.hidden)}>
            <HeadingMd h='2' {...{ title }} />
            <SubheadingSm title={subHeader} />
          </div>

          <animated.div {...bind()} className={cx(styles.stack, expanded && styles.expanded)} style={sliderSpring}>
            <Slides {...{ slides, slideRefs, slideSprings, expanded }} layoutClassName={styles.slidesLayout} />
          </animated.div>

          <animated.div ref={dotRef} className={cx(styles.dot, expanded && styles.expanded)} style={dotSpring}>
            <Dot onClick={handleExpand}>{expanded ? <Icon layoutClassName={styles.iconLayout} icon={iconClose} /> : __`component-textWithExpandableCarousel-explore` }</Dot>
          </animated.div>
        </div>
      </ContainerXl>
    </div>
  )

  function handleExpand() {
    transitionSlides(() => transitionDot(() => {
      setExpanded(!expanded)
      api.start({ x: 0 })
    }))
    trackInteraction({
      title: 'brand-information',
      action: expanded ? 'expand' : 'close',
      type: 'toggle',
    })
  }
}

function Slides({ slides, slideRefs, slideSprings, expanded, layoutClassName }) {
  return (
    <div className={cx(styles.componentSlides, expanded && styles.expanded, layoutClassName)}>
      {slides?.map((x, i) => (
        <animated.div className={styles.slide} ref={slideRefs[i]} key={i} style={{ ...slideSprings[i], zIndex: -i }}>
          <Slide image={x.image} description={x.description} showDescription={expanded} first={i === 0} />
        </animated.div>
      ))}
    </div>
  )
}

function Slide({ image, description, showDescription, first }) {
  return (
    <div className={styles.componentSlide}>
      <ImageCover
        aspectRatio={3 / 4}
        imgProps={{ draggable: 'false' }}
        {...{ image }}
      />

      {description && (
        <p className={cx(
          styles.slideDescription,
          showDescription && styles.visible,
          first && styles.first
        )}>
          {description}
        </p>
      )}
    </div>
  )
}

function Dot({ onClick, children, layoutClassName = undefined }) {
  return (
    <button
      data-x='click-to-toggle-slider'
      className={cx(styles.componentDot, layoutClassName)}
      {...{ onClick }}
    >
      {children}
    </button>
  )
}

function useSlider({ enabled }) {
  const elementRef = React.useRef(null)
  const [spring, api] = useSpring(() => ({ x: 0 }))
  const bind = useGesture({
    onDragStart: handleDragStart,
    onDrag: ({ down, last, offset: [ox], velocity: [vx], direction: [dx] }) => {
      if (last) api.start({ x: clamp({ min: elementRef.current.offsetWidth - elementRef.current.scrollWidth, max: 0, input: ox + dx * 75 * vx }), config: { velocity: dx * vx } })
      else if (down) api.set({ x: ox })
    }
  },
  {
    drag: {
      from: () => [spring.x.get(), 0],
      rubberband: true,
      enabled,
      bounds: () => ({ left: elementRef.current.offsetWidth - elementRef.current.scrollWidth, right: 0, top: 0, bottom: 0 })
    }
  })

  React.useEffect(
    () => {
      window.addEventListener('resize', handleResize)
      return () => { window.removeEventListener('resize', handleResize) }

      function handleResize() {
        api.set({ x: clamp({ min: elementRef.current.offsetWidth - elementRef.current.scrollWidth, max: 0, input: spring.x.get() }) })
      }
    }
  )

  return {
    bind: React.useCallback(
      (...args) => ({ ...bind(...args), ref: elementRef }),
      [bind]
    ),
    spring,
    api
  }

  function handleDragStart(e) {
    const [index] = e.args
    trackInteraction({
      title: 'brand-information',
      action: 'dragged',
      type: 'slider',
      index
    })
  }
}
